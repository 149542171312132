import * as React from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { ThemeProvider } from "@saleor/macaw-ui";
import { IntlProvider } from "react-intl";
import { useFormattedMessages } from "@/saleor-app-checkout/frontend/hooks/useFormattedMessages";
import AppContainer from "@/saleor-app-checkout/frontend/components/elements/AppContainer";
import AppProvider from "@/saleor-app-checkout/frontend/components/elements/AppProvider";
import PrivateSettingsProvider from "@/saleor-app-checkout/frontend/components/elements/PrivateSettingsProvider";
import "@saleor/checkout-storefront/dist/index.css";
import { useEffect } from "react";
import { RoutePropagator } from "@saleor/app-sdk/app-bridge/next";
import Script from "next/script";
import * as amplitude from "@amplitude/analytics-browser";
declare global {
  // eslint-disable-next-line no-var -- var is required here
  var __SALEOR_CHECKOUT_ENV__: string;
}
declare global {
  interface Window {
    rudderanalytics: any;
    heap: any;
    gtag: any;
    fbq: any;
    Grin: any;
    tp: any;
    VWO: any;
    rdt: any;
    amplitude: any;
    pintrk: any;
    twq: any;
    ttq: any;
  }
}
export default function App(props: AppProps) {
  const { Component, pageProps } = props;

  const { locale, messages } = useFormattedMessages();

  const version = [
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || "(unknown_env)",
    process.env.NEXT_PUBLIC_GIT_BRANCH || "(unknown_branch)",
    process.env.NEXT_PUBLIC_SENTRY_RELEASE || "(unknown_release)",
  ].join("-");

  const HEAP_PROJECT_ID = process.env.NEXT_PUBLIC_HEAP_PROJECT_ID || "saleor_app_checkout_heapid";
  const NMI_API_KEY = process.env.NEXT_PUBLIC_NMI_API_KEY;
  const AMPLITUDE_ID = process.env.NEXT_PUBLIC_AMPLITUDE_ID || "";

  const PIXEL_ID = process.env.NEXT_PUBLIC_PIXEL_ID || "";
  const metaPixelScript = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${PIXEL_ID}');
  fbq('track', 'PageView');`;

  const PIXEL_ID_2 = process.env.NEXT_PUBLIC_PIXEL_ID_2 || "";
  const metaPixelScript2 = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${PIXEL_ID_2}');
  fbq('track', 'PageView');`;

  const redditPixelId = process.env.NEXT_PUBLIC_REDDIT_PIXEL_ID || "";
  const redditScript = `
    !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};
    p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','${redditPixelId}');rdt('track', 'PageVisit');
  `;

  const PINTEREST_ID = process.env.NEXT_PUBLIC_PINTEREST_ID || "";
  const pinterestScript = `
  !function(e){if(!window.pintrk){window.pintrk = function () {
  window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
    n=window.pintrk;n.queue=[],n.version="3.0";var
    t=document.createElement("script");t.async=!0,t.src=e;var
    r=document.getElementsByTagName("script")[0];
    r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
  pintrk('load', '${PINTEREST_ID}');
  pintrk('page');`;

  const TIKTOK_PIXEL_ID = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID || "";
  const tiktokScript = `
    !function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
    var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
    ;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


    ttq.load('${TIKTOK_PIXEL_ID}');
    ttq.page();
    }(window, document, 'ttq');
  `;

  const X_PIXEL_ID = process.env.NEXT_PUBLIC_X_PIXEL_ID || "";
  const xPixelScript = `
    !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
    },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
    a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
    twq('config','${X_PIXEL_ID}');
  `;

  const RUDDERSTACK_ID = process.env.NEXT_PUBLIC_RUDDERSTACK_ID || "";
  const rudderstackScript = `
    !function(){"use strict";window.RudderSnippetVersion="3.0.60";var e="rudderanalytics";window[e]||(window[e]=[])
    ;var rudderanalytics=window[e];if(Array.isArray(rudderanalytics)){
    if(true===rudderanalytics.snippetExecuted&&window.console&&console.error){
    console.error("RudderStack JavaScript SDK snippet included more than once.")}else{rudderanalytics.snippetExecuted=true,
    window.rudderAnalyticsBuildType="legacy";var sdkBaseUrl="https://cdn.rudderlabs.com";var sdkVersion="v3"
    ;var sdkFileName="rsa.min.js";var scriptLoadingMode="async"
    ;var r=["setDefaultInstanceKey","load","ready","page","track","identify","alias","group","reset","setAnonymousId","startSession","endSession","consent"]
    ;for(var n=0;n<r.length;n++){var t=r[n];rudderanalytics[t]=function(r){return function(){var n
    ;Array.isArray(window[e])?rudderanalytics.push([r].concat(Array.prototype.slice.call(arguments))):null===(n=window[e][r])||void 0===n||n.apply(window[e],arguments)
    }}(t)}try{
    new Function('class Test{field=()=>{};test({prop=[]}={}){return prop?(prop?.property??[...prop]):import("");}}'),
    window.rudderAnalyticsBuildType="modern"}catch(i){}var d=document.head||document.getElementsByTagName("head")[0]
    ;var o=document.body||document.getElementsByTagName("body")[0];window.rudderAnalyticsAddScript=function(e,r,n){
    var t=document.createElement("script");t.src=e,t.setAttribute("data-loader","RS_JS_SDK"),r&&n&&t.setAttribute(r,n),
    "async"===scriptLoadingMode?t.async=true:"defer"===scriptLoadingMode&&(t.defer=true),
    d?d.insertBefore(t,d.firstChild):o.insertBefore(t,o.firstChild)},window.rudderAnalyticsMount=function(){!function(){
    if("undefined"==typeof globalThis){var e;var r=function getGlobal(){
    return"undefined"!=typeof self?self:"undefined"!=typeof window?window:null}();r&&Object.defineProperty(r,"globalThis",{
    value:r,configurable:true})}
    }(),window.rudderAnalyticsAddScript("".concat(sdkBaseUrl,"/").concat(sdkVersion,"/").concat(window.rudderAnalyticsBuildType,"/").concat(sdkFileName),"data-rsa-write-key","${RUDDERSTACK_ID}")
    },
    "undefined"==typeof Promise||"undefined"==typeof globalThis?window.rudderAnalyticsAddScript("https://polyfill-fastly.io/v3/polyfill.min.js?version=3.111.0&features=Symbol%2CPromise&callback=rudderAnalyticsMount"):window.rudderAnalyticsMount()
    ;var loadOptions={};rudderanalytics.load("${RUDDERSTACK_ID}","https://sevencellszpv.dataplane.rudderstack.com", { integrations: { All: true }})}}}();
  `;

  useEffect(() => {
    if (typeof window !== "undefined") {
      amplitude.init(`${AMPLITUDE_ID ?? ""}`, { autocapture: true });
    }
  }, []);

  useEffect(() => {
    globalThis.__SALEOR_CHECKOUT_ENV__ = version;
  }, [version]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
        <style>
          {`
            html,
            body {
              height: 100%;
              width: 100%;
              font-family: "Outfit", sans-serif !important;
            }
            *,
            *:after,
            *:before {
              box-sizing: border-box;
            }
            body {
              font-size: 1rem;
              margin: 0;
              background: transparent !important;
            }
          `}
        </style>
        <script id="meta-pixel" dangerouslySetInnerHTML={{ __html: metaPixelScript }} />
        <noscript>
          <img
            height={1}
            width={1}
            style={{ display: "none" }}
            src={`https://www.facebook.com/tr?id=${PIXEL_ID}&ev=PageView&noscript=1`}
          />
        </noscript>
        <script id="meta-pixel-2" dangerouslySetInnerHTML={{ __html: metaPixelScript2 }} />
        <noscript>
          <img
            height={1}
            width={1}
            style={{ display: "none" }}
            src={`https://www.facebook.com/tr?id=${PIXEL_ID_2}&ev=PageView&noscript=1`}
          />
        </noscript>
        <script id="reddit" dangerouslySetInnerHTML={{ __html: redditScript }} />
        <script id="pinterest" dangerouslySetInnerHTML={{ __html: pinterestScript }} />
        <noscript>
          <img
            height={1}
            width={1}
            style={{ display: "none" }}
            alt=""
            src={`https://ct.pinterest.com/v3/?event=init&tid=${PINTEREST_ID}&noscript=1`}
          />
        </noscript>
        <script dangerouslySetInnerHTML={{ __html: tiktokScript }} />
        <script dangerouslySetInnerHTML={{ __html: xPixelScript }} />
        <script dangerouslySetInnerHTML={{ __html: rudderstackScript }} />
      </Head>
      <Script id="heapio">
        {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};   
heap.load("${HEAP_PROJECT_ID}")`}
      </Script>
      <Script
        id="nmiApiKey"
        src="https://secure.safewebservices.com/token/Collect.js"
        data-tokenization-key={NMI_API_KEY}
        data-field-ccnumber-enable-card-brand-previews="true"
        data-style-sniffer="true"
      ></Script>
      <AppProvider>
        <PrivateSettingsProvider>
          <IntlProvider
            locale={locale}
            messages={messages}
            onError={() => null} // Hide missing translation warnings
          >
            {/* @ts-expect-error React 17 <-> 18 types mismatch */}
            <ThemeProvider ssr={true}>
              <RoutePropagator />
              <AppContainer>
                <Component {...pageProps} />
              </AppContainer>
            </ThemeProvider>
          </IntlProvider>
        </PrivateSettingsProvider>
      </AppProvider>
    </>
  );
}
